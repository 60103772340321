<!-- Provides an interface through which new "Collection Group" documents can be added to the database. -->
<template>
  <div class="form-container">
    <form>
      <TextInput
        v-model="collectionGroup.name"
        label="Collection Group Name"
        name="collection-group-name"
        required
      />
      <TextInput
        v-model="collectionGroup.id"
        label="Collection Group ID"
        name="collection-group-id"
        required
      />
      <TextArea
        v-model="collectionGroup.description"
        label="Collection Group Description"
        name="collection-group-description"
      />
      <MediaPicker
        v-model="collectionGroupImage"
        label="Collection Group Image"
        required
      />
    </form>

    <div class="submit-buttons">
      <button class="standard" type="button" @click="addCollectionGroup">Add New Collection Group</button>
      <ValidationErrors :errors="validationErrors" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      collectionGroup: {},
      collectionGroupImage: null,
      validationErrors: []
    }
  },
  methods: {
    ...mapMutations([
      'setGlobalState',
      'setLoading'
    ]),
    ...mapActions([
      'addNewCollectionGroup'
    ]),
    async addCollectionGroup () {
      try {
        this.setLoading(true)
        this.setGlobalState({ target: 'loadingStatus', val: 'Uploading collection group image asset. Please don\'t close this browser window.' })
        const res = await this.fbCall('cloudinary-uploadAsset', {
          path: `stores/${process.env.VUE_APP_MARKET_ID}/collection-groups/${this.collectionGroup.id}/image`,
          asset: this.collectionGroupImage.rawData
        })
        this.collectionGroup.image = res.data.secure_url
      } catch (err) {
        console.log(err)
        this.setLoading(false)
        this.addNotification('Error uploading collection group image.', 'error')
        return
      }
      try {
        this.setGlobalState({ target: 'loadingStatus', val: 'Adding new collection group to database. Please don\'t close this browser window.' })
        await this.addNewCollectionGroup(this.collectionGroup)
      } catch (err) {
        console.log(err)
        this.setLoading(false)
        this.addNotification('Error adding new collection group.', 'error')
        return
      }
      this.setLoading(false)
      this.$router.push('/collection-groups')
    }
  }
}
</script>

<style>

</style>
